<template>
    <b-overlay class="content-wrapper" variant="white" :show="isLoading" spinner-variant="primary" blur="0"
        opacity=".75" rounded="sm">
        <PageHeader :screenName="pageTitle" :linkItems="linkItems" />

        <main class="card p-2" v-if="!isLoading">
            <section class="form">
                <validation-observer ref="UnidadesRules" v-slot="{ validate }">
                    <b-form class="section-unidade">
                        <b-row>
                            <b-col lg="4" md="4" sm="4" class="mb-1">
                                <Tipo @updated="form.tipo = $event" :value="unidade.tipo" :cadastro="false" :desabilitarCamposDadosCriacao="desabilitarCamposDadosCriacao"/>
                            </b-col>
                            <b-col lg="4" md="4" sm="4" class="mb-1">
                                <DepartamentoRegional @updated="handleSelectDepartamento"
                                    :value="unidade.id_departamento" :desabilitarCamposDadosCriacao="desabilitarCamposDadosCriacao"/>
                            </b-col>
                            <b-col lg="4" md="4" sm="4" class="mb-1">
                                <Regiao @updated="form.regiao = $event" :departamento="form.departamentoRegional"
                                    :value="unidade.id_regiao" :key="indice.regiao" :desabilitarCamposDadosCriacao="desabilitarCamposDadosCriacao"/>
                            </b-col>
                            <b-col lg="6" md="6" sm="6" class="mb-1">
                                <NomeUnidadeOperacional @updated="form.nomeUnidadeOperacional = $event"
                                    :value="unidade.descricao" :desabilitarCamposDadosCriacao="desabilitarCamposDadosCriacao"/>
                            </b-col>
                            <b-col lg="6" md="6" sm="6" class="mb-1">
                                <Cnpj @updated="form.cnpj = $event" :value="unidade.cnpj" :desabilitarCamposDadosCriacao="desabilitarCamposDadosCriacao"/>
                            </b-col>
                            <b-col lg="6" md="6" sm="6" class="mb-1">
                                <CodigoSmdUnidadeOperacional @updated="form.codigoSMD = $event"
                                    :value="unidade.codigo_smd" :desabilitarCamposDadosCriacao="desabilitarCamposDadosCriacao"/>
                            </b-col>
                            <b-col lg="6" md="6" sm="6" class="mb-1">
                                <CodigoParaIntegracaoExterna @updated="form.codigoIntegracaoExterna = $event"
                                    :value="unidade.codigo_smd_integracao" :desabilitarCamposDadosCriacao="desabilitarCamposDadosCriacao"/>
                            </b-col>
                            <b-col lg="4" md="4" sm="4" class="mb-1">
                                <Cep @updated="handleSelectCep" :value="unidade.cep"/>
                            </b-col>
                            <b-col lg="8" md="8" sm="8" class="mb-1">
                                <Logradouro @updated="form.logradouro = $event" :value="form.logradouro"
                                    :key="indice.logradouro"/>
                            </b-col>
                            <b-col lg="4" md="4" sm="4" class="mb-1">
                                <Numero @updated="form.numero = $event" :value="unidade.numero"/>
                            </b-col>
                            <b-col lg="4" md="4" sm="4" class="mb-1">
                                <PontoReferencia @updated="form.pontoReferencia = $event"
                                    :value="unidade.ponto_referencia"/>
                            </b-col>
                            <b-col lg="4" md="4" sm="4" class="mb-1">
                                <Bairro @updated="form.bairro = $event" :value="form.bairro" :key="indice.bairro"/>
                            </b-col>
                            <b-col lg="6" md="6" sm="6" class="mb-1">
                                <Estado @updated="handleSelectEstado" :value="form.estado.uf"/>
                            </b-col>
                            <b-col lg="6" md="6" sm="6" class="mb-1">
                                <Cidade @updated="form.cidade = $event" :estado="form.estado" :value="form.id_cidade"
                                    @setCidades="cidades = $event" :key="indice.cidade"/>
                            </b-col>
                            <b-col lg="6" md="6" sm="6" class="mb-1">
                                <NomeResponsavel @updated="form.nomeResponsavel = $event"
                                    :value="unidade.nome_responsavel_unidade" />
                            </b-col>
                            <b-col lg="6" md="6" sm="6" class="mb-1">
                                <EmailResponsavel @updated="form.emailResponsavel = $event"
                                    :value="unidade.email_responsavel_unidade" />
                            </b-col>
                        </b-row>

                        <b-row>
                            <VincularCidades :departamento="form.departamentoRegional"
                                :cidadeBancoEdicao="unidade.cidade" :key="indice.cidadesVinculadas"
                                @updated="form.cidadesVinculadas = $event" />
                        </b-row>

                        <b-row class="buttons mt-3">
                            <b-col>
                                <b-button id="save-unidade" class="mr-2" variant="custom-blue"
                                    :disabled="temCamposObrigadorioVazio || saving" @click.prevent="handleSubmit(true)">
                                    <feather-icon icon="CheckIcon" class="mr-50" />
                                    <span class="align-middle">Salvar alterações</span>
                                    <span v-if="saving" class="spinner-border spinner-border-sm ml-1" role="status"
                                        aria-hidden="true"></span>
                                </b-button>
                                <b-button id="cancel-register-unidade" variant="outline-danger"
                                    @click.prevent="alteraVisibilidadeConfirmModal(true)">
                                    <feather-icon icon="XIcon" class="mr-50" />
                                    <span class="align-middle">Cancelar</span>
                                </b-button>
                            </b-col>
                        </b-row>
                    </b-form>
                </validation-observer>
            </section>

        </main>

        <section>
            <ConfirmModal itemIdentifier="unidade" :modalShow="modal.showModal" :typeModal="modal.typeModal"
                @confirmed="redirecionarParaOutraPagina('unidade-list')"
                @canceled="alteraVisibilidadeConfirmModal(false)" />

            <DepartamentoModal :modalShow="modalErroRegiao.showModal" :typeModal="modalErroRegiao.caseModal"
                @confirmed="redirecionarParaOutraPagina('departamento-view')" />

            <SucessoModal
                :modalShow="modalSuccess.showModal"
                :typeModal="modalSuccess.typeModal"
                :caseModal="modalSuccess.caseModal"
                @confirmed="redirecionarParaOutraPagina('unidade-list')"
            />

            <ModalShowError
                :modalShow="modalError.showModal"
                :typeModal="modalError.typeModal"
                :infoText="modalError.infoText"
                @confirmed="alteraVisibilidadeErrorModal(false)"
            />
        </section>
    </b-overlay>
</template>

<script>
import PageHeader from '@/views/components/custom/page-header/PageHeader.vue';
import { getUserData } from "@/auth/utils";
import { BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BOverlay } from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VincularCidades from './parts/VincularCidades.vue';
import Tipo from './parts/Tipo.vue';
import DepartamentoRegional from './parts/DepartamentoRegional.vue';
import NomeUnidadeOperacional from './parts/NomeUnidadeOperacional.vue';
import Cnpj from './parts/Cnpj.vue';
import CodigoSmdUnidadeOperacional from './parts/CodigoSmdUnidadeOperacional.vue';
import CodigoParaIntegracaoExterna from './parts/CodigoParaIntegracaoExterna.vue';
import Cep from './parts/Cep.vue';
import Logradouro from './parts/Logradouro.vue';
import Numero from './parts/Numero.vue';
import PontoReferencia from './parts/PontoReferencia.vue';
import Bairro from './parts/Bairro.vue';
import Estado from './parts/Estado.vue';
import Cidade from './parts/Cidade.vue';
import NomeResponsavel from './parts/NomeResponsavel.vue';
import EmailResponsavel from './parts/EmailResponsavel.vue';
import Regiao from './parts/Regiao.vue';

import ConfirmModal from '@/views/components/custom/modals/ConfirmModal.vue';
import DepartamentoModal from '@/views/components/custom/modals/ModalErrorDepartamento.vue';
import handleErrorsService from './Services/handleErros';
import { getIdUnidade, getAcronymUnidade } from '@core/utils/store/getStore';
import { setIdUnidade, setAcronymUnidade } from "@core/utils/store/setStore";
import { getPayload, temCamposObrigadorioVazio, formatarEditarUnidade } from './Services/handlePayload.js';
import SucessoModal from '@/views/components/custom/modals/SucessoCriacaoEdicaoModal.vue'
import ModalShowError from '@/views/components/custom/modals/ModalShowError.vue';
import {actions, subjects} from "@/libs/acl/rules";
import {tipoUnidade} from "@/enum/tipoUnidade";

export default {
    title: 'Editar unidade',
    components: {
        PageHeader,
        BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BOverlay,
        ValidationProvider, ValidationObserver,
        VincularCidades,
        Tipo,
        DepartamentoRegional,
        NomeUnidadeOperacional,
        Cnpj,
        CodigoSmdUnidadeOperacional,
        CodigoParaIntegracaoExterna,
        Cep,
        Logradouro,
        Numero,
        PontoReferencia,
        Bairro,
        Estado,
        Cidade,
        NomeResponsavel,
        EmailResponsavel,
        ConfirmModal,
        DepartamentoModal,
        Regiao,
        SucessoModal,
        ModalShowError,
    },
    data() {
        return {
            userData: getUserData(),
            linkItems: [
                { name: 'Unidades Operacionais', routeName: 'unidade-list', },
                { name: 'Editar', active: true, }
            ],
            pageTitle: 'Editar unidade',
            isLoading: true,
            isDisabledField: true,
            saving: false,
            cidades: [],
            semCidadeVinculada: false,
            indice: {
                departamento: 0,
                estado: 0,
                cidade: 0,
                logradouro: 0,
                bairro: 0,
                cidadesVinculadas: 0,
                tipo: 0,
                regiao: 0,
                departamentoRegional: 0,
                nomeUnidadeOperacional: 0,
                cnpj: 0,
                codigoSMD: 0,
                codigoIntegracaoExterna: 0,
                numero: 0,
                nomeResponsavel: 0,
                emailResponsavel: 0,
                cep: 0,
                errors: 0,
            },
            unidade: null,
            form: {
                tipo: null,
                departamentoRegional: null,
                nomeUnidadeOperacional: null,
                cnpj: null,
                codigoSMD: null,
                codigoIntegracaoExterna: null,
                cep: null,
                logradouro: null,
                numero: null,
                pontoReferencia: null,
                bairro: null,
                estado: { uf: null },
                cidade: null,
                nomeResponsavel: null,
                emailResponsavel: null,
                cidadesVinculadas: null,
                regiao: null,
                idUnidadeContatoResponsavel: null,
                idUsuarioResponsavel: null,
                idUnidadeResponsavel: null,
            },
            modal: {
                showModal: false,
                typeModal: 'danger'
            },
            modalSuccess: {
                showModal: false,
                typeModal: 'success',
                caseModal: 'edicao'
            },
            modalErroRegiao: {
                showModal: false,
                caseModal: 'sem-regiao-criacao-unidade'
            },
            modalError: {
                showModal: false,
                typeModal: 'error',
                infoText: ''
            },

            desabilitarCamposDadosCriacao: false,
            podeUnidadePropriaDadosCriacaoAtualizar: false,
        }
    },
    beforeMount() {
        const hasModalError = this.$helpers.verifyIfCanOpenModalError(getIdUnidade(), this.$route.params.id_unidade);
        const hasIdEntityRouteParam = this.$helpers.verifyIfHasIdEntityRouteParam(getIdUnidade(), this.$route.params.id_unidade);

        if (hasModalError)
            return this.openModalError();

        if (hasIdEntityRouteParam) {
            setIdUnidade(this.$route.params.id_unidade);
            setAcronymUnidade(this.$route.params.acronym);
        }

        this.podeUnidadePropriaDadosCriacaoAtualizar = this.$can(actions.ATUALIZAR, subjects.UNIDADE_PROPRIA_DADOS_CRIACAO);
        this.linkItems[1].name = 'Editar: ' + getAcronymUnidade();
        this.pageTitle = 'Editar ' + getAcronymUnidade();
        this.getUnidade(getIdUnidade())
    },
    methods: {
        setForm(unidade) {
            this.form.cep = unidade.cep
            this.form.cnpj = unidade.cnpj
            this.form.logradouro = unidade.logradouro
            this.form.bairro = unidade.bairro
            this.form.estado.uf = unidade.uf_cidade_local_fisico
            this.form.id_cidade = unidade.id_cidade
            this.form.logradouro = unidade.logradouro

            this.form.idUnidadeContatoResponsavel = unidade.idUnidadeContatoResponsavel;
            this.form.idUsuarioResponsavel = unidade.idUsuarioResponsavel;
            this.form.idUnidadeResponsavel = unidade.idUnidadeResponsavel;
        },
        getUnidade(idUnidade) {
            this.$http.get(this.$api.unidadeParameter(idUnidade)).then(({ data }) => {
                this.unidade = formatarEditarUnidade(data)
                this.setForm(this.unidade)
                this.isLoading = false

                if (this.unidade.tipo === tipoUnidade.PROPRIA && !this.podeUnidadePropriaDadosCriacaoAtualizar) {
                  this.desabilitarCamposDadosCriacao = true;
                }
            }).catch((error) => {
                this.redirecionarParaOutraPagina('unidade-list')
            });
        },
        handleSelectDepartamento(departamento) {
          const temDepartamentoRegional = this.form.departamentoRegional !== null;
          const temDiferenteDepartamento = this.form.departamentoRegional?.id_departamento !== departamento.id_departamento;
          const alterouDepartamento = temDepartamentoRegional && temDiferenteDepartamento;

            if (alterouDepartamento) {
                this.unidade.cidade = []
            }

            this.form.departamentoRegional = departamento
            this.form.regiao = null;
            this.indice.regiao++;
            this.indice.cidadesVinculadas++
        },
        handleSelectEstado(estado) {
            this.form.estado = estado;
            this.form.cidadesVinculadas = []
            this.indice.cidade++;
        },
        handleSelectCep(dados) {
            this.form.cep = dados.cep
            this.form.logradouro = dados.street
            this.form.bairro = dados.neighborhood

            this.indice.logradouro++
            this.indice.bairro++
        },
        salvar() {
            this.$http.put(this.$api.unidadeParameter(getIdUnidade()), this.payload).then(() => {
                this.alteraVisibilidadeSucessoModal(true);
            }).catch(({ response: { data, status } }) => {
                if (status == 409) {
                    this.alteraVisibilidadeDepartamentoModal(true)
                } else {
                    this.modalError.infoText = handleErrorsService.format(data.errors)
                    this.alteraVisibilidadeErrorModal(true)
                }
            })
          this.saving = false;
        },
        handleSubmit() {
            this.submitForm();
        },
        submitForm() {
            this.$refs.UnidadesRules.validate().then((isValid) => {
                if (isValid) {
                  this.saving = true;
                  this.salvar();
                }
            });
        },
        alteraVisibilidadeConfirmModal(show) {
            this.modal.showModal = show;
        },
        alteraVisibilidadeSucessoModal(show) {
            this.modalSuccess.showModal = show;
        },
        alteraVisibilidadeDepartamentoModal(show) {
            this.modalErroRegiao.showModal = show;
        },
        alteraVisibilidadeErrorModal(show) {
            this.modalError.showModal = show;
        },
        redirecionarParaOutraPagina(name) {
            this.alteraVisibilidadeConfirmModal(false);
            this.alteraVisibilidadeSucessoModal(false);
            this.alteraVisibilidadeDepartamentoModal(false);
            this.$router.push({ name });
        },
    },
    computed: {
        temCamposObrigadorioVazio() {
            return temCamposObrigadorioVazio(this.form)
        },
        payload() {
            return getPayload(this.form, getIdUnidade())
        }
    }
}

</script>

<style lang="scss">
.section-unidade {
  .form-control:disabled,
  .vs--disabled {
    background-color: #efefef !important;

    .vs__dropdown-toggle,
    .vs__search,
    .vs__selected,
    .vs__open-indicator{
      background-color: inherit !important;
    }
  }
}
</style>
